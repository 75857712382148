<template>
  <b-container fluid>
    <b-row>
        <b-col v-if="this.details.org_id">
            <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
            {{  }}
            </list-report-head>
        </b-col>
    </b-row>
    <b-row>
      <div class="invoice text-dark ml-5" id="printArea">
        <div class="invoice-logo"></div>
        <div class="invoice-sec-1">
          <div class="invoice-sec-1-ref">
            <div class="to-invoice">
              <p>{{$t('globalTrans.to')}},</p>
              <p></p>
              <p>
                {{$t('globalTrans.organization')}}:
                {{ ($i18n.locale==='bn') ? details.org_bn : details.org }}
              </p>
              <p>
                {{ $t('elearning_tpm.vanue') }}:
                {{ $i18n.locale === 'bn' ? details.venue_bn : details.venue }}
              </p>
            </div>
          </div>
          <div class="invoice-sec-1-date">
            <table class="table" style="width:100%" border="1">
              <thead class="thead">
                <tr>
                  <th>{{$t('elearning_venue.rent_amount')}}</th>
                  <th>{{$t('elearning_venue.total_days')}}</th>
                  <th>{{$t('globalTrans.totalAmount')}}</th>
                  <th>{{$t('elearning_venue.vat_tax')}} (%)</th>
                  <th>{{$t('elearning_venue.vat_tax_amount')}}</th>
                  <th>{{$t('elearning_venue.electricity_bill_amount')}}</th>
                  <th>{{$t('elearning_venue.gas_bill_amount')}}</th>
                  <th>{{$t('elearning_accommodation.service_charge')}}</th>
                  <th>{{$t('elearning_accommodation.other_deduction')}}</th>
                  <th>{{$t('globalTrans.payment_amount')}}</th>
                </tr>
                <tr>
                  <th>{{$n(details.rent_amount)}}</th>
                  <th>{{$n(details.total_days)}}</th>
                  <th>{{$n(details.total_amount)}}</th>
                  <th>{{$n(details.vat_tax)}}</th>
                  <th>{{$n(details.vat_tax_amount)}}</th>
                  <th>{{$n(details.electricity_bill_amount)}}</th>
                  <th>{{$n(details.gas_bill_amount)}}</th>
                  <th>{{$n(details.service_charge)}}</th>
                  <th>{{$n(details.other_deduction)}}</th>
                  <th>{{$n(details.payment_amount)}}</th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="invoice-sec-1-date">
            <p>
              {{$t('globalTrans.date')}}: {{ details.bill_generate_date | dateFormat }}
            </p>
          </div>
        </div>
        <div class="invoice-greeting">
          <p>{{$t('globalTrans.thank_you')}}</p>
          <p>MOA Software</p>
        </div>
      </div>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'

export default {
  props: ['id'],
  components: {
    ListReportHead
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.details = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      details: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      officeTypeList: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.type_id === typeId)
       }
       return trainingCategoryList
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tpm.payment')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.details, this)
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.category_id === categoryId)
      }
      return trainingTitleList
    }
  }
}
</script>
